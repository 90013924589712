import IconClose from '@/assets/icons/icon-close.svg?react';
import IconMenu from '@/assets/icons/icon-menu.svg?react';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

export function MobileNavCollapsible({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isOpen && windowSize.width > 768) {
      setIsOpen(false);
    }
  }, [isOpen, windowSize.width]);

  useEffect(() => {
    document.documentElement.classList.toggle('overflow-hidden', isOpen);
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        className="relative z-[60] p-2 text-black transition-colors hover:text-black/80 md:hidden"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        aria-expanded={isOpen}
        aria-controls="mobile-menu"
        aria-label="Toggle mobile menu"
      >
        {isOpen ? (
          <IconClose
            className={cn('size-6 text-white sm:size-7')}
            aria-hidden="true"
          />
        ) : (
          <IconMenu className={cn('size-4 sm:size-7')} aria-hidden="true" />
        )}
        <span className="sr-only">{isOpen ? 'Close menu' : 'Open menu'}</span>
      </button>
      {isOpen && (
        <div
          id="mobile-menu"
          className="fixed inset-0 z-50 bg-black/80 backdrop-blur-sm"
          role="dialog"
          aria-modal="true"
          aria-label="Mobile navigation menu"
        >
          {children}
        </div>
      )}
    </>
  );
}
